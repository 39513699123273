<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div
                class="alert alert-fill alert-warning alert-icon"
                v-if="editingMode"
              >
                <em class="icon ni ni-alert-circle"></em>
                <strong>Warning!</strong>. Make sure that items you are going to
                edit or delete are not involved in any transaction.
              </div>
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title mb-0">
                      <span v-if="editingMode"
                        >Edit Stock Transfer
                        <a href="javascript:void(0)"
                          >#{{ record.code }}</a
                        ></span
                      >
                      <span v-else>Stock Transfer</span>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p>Transfer items to branches</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li
                            style="width: 180px"
                            v-if="
                              isBranchTransfer &&
                              this.$helper.empty(loggedUser.branch)
                            "
                          >
                            <multiselect
                              v-model="selectedBranch"
                              :options="branches"
                              placeholder="Select Branch"
                              label="name"
                              track-by="name"
                            ></multiselect>
                          </li>
                          <li
                            style="width: 180px"
                            v-if="
                              isProductionTransfer &&
                              this.$helper.empty(loggedUser.source)
                            "
                          >
                            <multiselect
                              v-model="selectedSource"
                              :options="sources"
                              placeholder="Production Site"
                              label="name"
                              track-by="name"
                            ></multiselect>
                          </li>
                          <li style="width: 180px" v-if="itemsUrl !== null">
                            <div class="form-control-wrap w-100">
                              <type-head
                                :url="itemsUrl"
                                label="name"
                                tracker="name"
                                placeholder="Select Item"
                                @changed="handleSelectedItem"
                              ></type-head>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Qty In</span></div>
                    <div class="nk-tb-col"><span>Requested. Qty</span></div>
                    <div class="nk-tb-col"><span>Total Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">
                          {{ formatMoney(product.price) }}</span
                        ></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        >{{ $helper.formatNumber(product.current_qty) }}
                        {{ product.unit }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="
                            `required|max_value:${
                              items[i].current_qty + items[i].requested_qty
                            }`
                          "
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(product)"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Quantity' + i)"
                          >Quantity is required and <br />
                          must be less or equal to
                          {{ items[i].current_qty + items[i].requested_qty }}
                        </span>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">
                        <span> {{ formatMoney(items[i].total_amount) }}</span>
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6
                      class="d-flex mb-3 align-items-center justify-content-end"
                    >
                      Grand Total:
                      <span class="ml-auto">{{ formatMoney(grandTotal) }}</span>
                    </h6>
                  </div>
                  <div class="w-100">
                    <div
                      class="d-flex justify-content-end align-items-center actions w-100"
                    >
                      <div class="col-2 px-1">
                        <multiselect
                          v-if="requisition.type != 'PRODUCTION'"
                          v-model="destination"
                          :options="destinations"
                          group-values="rows"
                          group-label="destination"
                          placeholder="Destination"
                          label="name"
                          track-by="name"
                          @select="setDestination"
                        ></multiselect>

                        <multiselect
                          v-else
                          v-model="source"
                          :options="sources"
                          placeholder="Select source"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>
                      <div class="form-group col-2 mb-0 px-1">
                        <input
                          type="text"
                          v-model="transporter"
                          class="form-control"
                          placeholder="Employee Name"
                        />
                      </div>
                      <div class="form-group col-2 mb-0 px-1">
                        <input
                          type="date"
                          v-model="delivery_date"
                          class="form-control"
                          placeholder="Delivery Date"
                          :readonly="isBranchTransfer"
                        />
                      </div>
                      <wolf-button
                        classes="btn btn-primary ml-1"
                        @clicked="handleSubmit"
                        :disabler="submitDisabled || !transporter"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Save</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added items</h6>
                              <p>
                                No items found in Stock Transfer list. Search
                                for product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
export default {
  name: "Deliver",
  components: { Multiselect, TypeHead },
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    sources: [],
    branch: null,
    source: null,
    destination: null,
    transporter: null,
    object_id: null,
    object_type: null,
    requisition: {},
    delivery_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
    record: {},
    editingMode: false,
    hasReference: false,
    selectedSource: null,
    selectedBranch: null,
  }),
  computed: {
    submitDisabled() {
      return (
        (!this.branch && !this.source) ||
        !this.items.length ||
        (!this.editingMode && this.items.some((item) => !item.quantity)) ||
        this.items.some((item) => !item.current_qty || item.current_qty == 0)
      );
    },
    isRequisition() {
      return this.object_type == "REQUISITION";
    },
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    ...mapState({
      branches: (state) => state.branches,
    }),
    isProductionTransfer() {
      return this.$route.name == "ProductionTransfer";
    },
    isBranchTransfer() {
      return this.$route.name == "BranchTransfer";
    },
    destinations() {
      let branches = this.branches;
      if (this.isBranchTransfer && this.selectedBranch) {
        branches = branches.filter((row) => row.id != this.selectedBranch.id);
      }
      const result = [{ destination: "Branches", rows: branches }];
      if (!this.isProductionTransfer && !this.selectedBranch) {
        result.push({ destination: "Productions", rows: this.sources });
      }
      return result;
    },
    itemsUrl() {
      if (!this.isProductionTransfer && !this.selectedBranch) {
        return "stock/products/search";
      }
      if (this.selectedSource) {
        return `production/items?source_id=${this.selectedSource.id}`;
      }
      if (this.selectedBranch) {
        return `branches/items/stock-items?branch_id=${this.selectedBranch.id}`;
      }
      return null;
    },
  },
  beforeCreate() {
    const reference = this.$route.params.reference;
    if (reference) {
      localStorage.removeItem("deliveryItems");
      this.$http.get(`requisitions/items/${reference}`).then((response) => {
        const items = response.data.items;
        items.forEach((item) => {
          this.items.push({
            id: item.product_id,
            name: item.product.name,
            source: item.product.source,
            unit: item.product.unit,
            quantity: item.requested_quantity,
            requested_qty: item.requested_quantity,
            total_amount:
              (item.product.cost_price || item.product.cost) *
              item.requested_quantity,
            price: item.product.cost_price || item.product.cost,
            current_qty:
              item.product.status == "PRODUCTION"
                ? item.stock_quantity
                : item.product.quantity,
          });
          this.selectedItemIds.push(item.product.id);
        });

        const requisition = response.data.requisition;
        this.requisition = requisition;
        this.object_type = "REQUISITION";
        this.object_id = requisition.id;
        this.branch = requisition.branch;
        this.source = requisition.source;
        this.destination = this.branch || this.source;
        this.transporter = requisition.initiator;
      });
    } else {
      const action = this.$route.query.action;
      const reference = this.$route.query.reference;
      if (typeof action == "string" && action == "edit") {
        this.editingMode = true;
        localStorage.removeItem("deliveryItems");
        this.$http
          .get(`/reports/transfer/${reference}/voucher`)
          .then((response) => {
            const items = response.data.items;
            this.record = response.data.record;
            this.branch = this.record.branch;
            this.source = this.record.source;
            this.destination = this.branch || this.source;
            this.transporter = this.record.transporter;
            this.delivery_date = new Date(this.record.delivery_date)
              .toLocaleString("fr-CA", { timeZone: TIMEZONE })
              .slice(0, 10);
            items.forEach((item) => {
              this.items.push({
                id: item.product.id,
                name: item.product.name,
                source: item.product.source,
                unit: item.product.unit,
                quantity: item.quantity,
                requested_qty: item.quantity,
                total_amount: item.quantity * item.price,
                price: item.price,
                current_qty:
                  item.product.status == "PRODUCTION"
                    ? item.stock_quantity
                    : item.product.quantity,
              });
              this.selectedItemIds.push(item.product.id);
            });
          });
      }
    }
  },
  created() {
    const reference = this.$route.params.reference;
    if (!reference) {
      let cart = localStorage.getItem("deliveryItems");
      if (cart) {
        let result = JSON.parse(cart);
        /**
         * Check if saved items belongs to the current route
         */
        if (this.isProductionTransfer) {
          if (!result.isProduction) {
            localStorage.removeItem("deliveryItems");
          } else {
            this.items = result.items;
            this.selectedItemIds = result.selectedItemIds;
          }
        } else {
          if (result.isProduction) {
            localStorage.removeItem("deliveryItems");
          } else {
            this.items = result.items;
            this.selectedItemIds = result.selectedItemIds;
          }
        }
      }
    } else {
      this.hasReference = true;
      localStorage.removeItem("deliveryItems");
    }
    const action = this.$route.query.action;
    this.editingMode = typeof action == "string" && action == "edit";
  },
  beforeDestroy() {
    if (!this.editingMode && !this.hasReference) {
      if (this.items.length) {
        localStorage.setItem(
          "deliveryItems",
          JSON.stringify({
            items: this.items,
            selectedItemIds: this.selectedItemIds,
            isProduction: this.isProductionTransfer,
          })
        );
      }
    }
  },
  watch: {
    items() {
      if (!this.items.length) {
        localStorage.removeItem("deliveryItems");
      }
    },
    $route() {
      location.reload();
    },
    selectedSource() {
      this.items = [];
      this.branch = {};
      this.source = {};
      this.selectedItemIds = [];
      localStorage.removeItem("deliveryItems");
    },
    selectedBranch() {
      this.items = [];
      this.branch = {};
      this.source = {};
      this.selectedItemIds = [];
      localStorage.removeItem("deliveryItems");
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$helper.empty(this.loggedUser.source)) {
        this.selectedSource = this.loggedUser.source;
      }

      if (!this.$helper.empty(this.loggedUser.branch)) {
        this.selectedBranch = this.loggedUser.branch;
      }

      //if (!this.isProductionTransfer) {
      this.$http.get("production/sources").then((response) => {
        this.sources = response.data.sources;
      });
      //}
    });
  },
  methods: {
    setDestination(destination) {
      if (!this.$helper.empty(destination)) {
        this.branch = null;
        this.source = null;
        let keys = Object.keys(destination);
        if (keys.includes("phone")) this.branch = destination;
        else this.source = destination;
      }
    },
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
      let reference = this.$route.params.reference;
      if (!reference) {
        localStorage.setItem(
          "deliveryItems",
          JSON.stringify({
            items: this.items,
            selectedItemIds: this.selectedItemIds,
            isProduction: this.isProductionTransfer,
          })
        );
      }
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        if (item.quantity == 0) {
          console.log(item);
          this.$notifier({
            type: "warning",
            message: "Item Quantity is 0",
          });
        } else {
          this.items.unshift({
            id: item.id,
            name: item.name,
            source: item.source,
            unit: item.unit,
            quantity: 1,
            requested_qty: 0,
            total_amount: item.cost_price || item.cost,
            price: item.cost || item.cost_price,
            current_qty: item.quantity,
          });
          this.selectedItemIds.unshift(item.id);
        }
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let source = null,
            branch = null;
          if (!this.$helper.empty(this.branch)) branch = this.branch.id;
          if (!this.$helper.empty(this.source)) source = this.source.id;
          if (this.isProductionTransfer) {
            this.object_type = "PRODUCTION";
          }
          if (this.isBranchTransfer) {
            this.object_type = "BRANCH_TRANSFER";
          }
          this.$http
            .post(
              "stock/products/deliver",
              this.$helper.generateFormData({
                branch: branch,
                source: source,
                items: this.items,
                delivery_date: this.delivery_date,
                transporter: this.transporter,
                object_id: this.object_id,
                object_type: this.object_type,
                requisition_id: this.requisition.id || null,
                record: this.record.id || null,
                record_items: this.selectedItemIds,
                sender_source: this.selectedSource
                  ? this.selectedSource.id
                  : null,
                sender_branch: this.selectedBranch
                  ? this.selectedBranch.id
                  : null,
              })
            )
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                localStorage.removeItem("deliveryItems");
                let action = this.$route.query.action;
                if (typeof action == "string" && action == "edit") {
                  this.$router.replace({ name: "TransferReport" });
                } else {
                  if (!this.$helper.empty(this.requisition)) {
                    if (!this.isProductionTransfer) {
                      this.$router.replace({ name: "Requests" });
                    } else {
                      this.$router.replace({ name: "ProductionRequisitions" });
                    }
                    this.$store.commit("REDUCE_NOTIFICATIONS", {
                      key: "requisitions",
                      total: 1,
                    });
                  } else {
                    this.items = [];
                    this.branch = {};
                    this.source = {};
                    location.reload();
                  }
                }
              }
            });
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
