<template>
  <div class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <span v-if="editingMode">
                        Edit Purchase Order
                        <a href="javascript:void(0)"
                          >#{{ $helper.generateVoucherNo(record.id) }}</a
                        >
                      </span>
                      <span v-else>Manage Purchase Orders</span>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} products</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-1 float-right">
                          <li class="w-100">
                            <div class="form-control-wrap w-100">
                              <type-head
                                url="stock/products/search"
                                label="name"
                                tracker="name"
                                placeholder="Search by item name"
                                @changed="handleSelectedItem"
                              ></type-head>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <!-- <div class="nk-tb-col"><span>Code</span></div> -->
                    <div class="nk-tb-col"><span>Type</span></div>
                    <div class="nk-tb-col"><span>Qty In</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Total Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in items"
                    :key="'order-item' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{
                          item.name || item.product.name
                        }}</span></span
                      >
                    </div>
                    <!-- <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title"
                          >#{{ item.code || item.product.code }}</span
                        ></span
                      >
                    </div> -->
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{
                          item.type || item.product.type
                        }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span v-if="!$helper.empty(item.product)">
                        {{
                          `${$helper.formatNumber(item.product.quantity)} ${
                            item?.product?.unit
                          }`
                        }}</span
                      >
                      <span v-else>
                        {{
                          `${$helper.formatNumber(item.quantity)} ${item?.unit}`
                        }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{
                              appSettings.currency
                            }}</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            v-model="items[i].cost"
                            :placeholder="`Price in ${appSettings.currency}`"
                            :name="'Cost Price' + i"
                            :class="{
                              'is-invalid': errors.has('Cost Price' + i),
                            }"
                            v-validate="'required'"
                            @keyup="findTotalAmount(item)"
                          />
                        </div>
                        <span
                          class="text-danger"
                          v-if="errors.has('Cost Price' + i)"
                          >Cost Price is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{
                            item?.unit || item?.product?.unit
                          }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].store_purchase"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required'"
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(item)"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Quantity' + i)"
                          >Quantity is required and
                        </span>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">
                        {{ formatMoney(items[i].total_amount) }}
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        v-if="!$helper.empty(item.product) && editingMode"
                        @click.prevent="
                          $alert({
                            title: 'Removing from purchase order',
                            content: `Are you sure you want to remove this product (${item.product.name})?`,
                            classes: 'btn btn-danger',
                            actionText: 'Continue',
                            closeText: 'Discard',
                            action: () => {
                              handleOrderItemDeletion(item, i);
                            },
                          })
                        "
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                      <a
                        v-else
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6 class="d-flex mb-3 align-items-center">
                      Grand Total:
                      <span class="ml-auto">{{ formatMoney(grandTotal) }}</span>
                    </h6>
                  </div>
                  <div class="w-100">
                    <div
                      class="d-flex justify-content-end align-items-center actions w-100"
                    >
                      <div class="col-2 px-1">
                        <multiselect
                          v-model="supplier"
                          :options="suppliers"
                          placeholder="Select supplier"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>
                      <div class="form-group col-2 px-1 mb-0">
                        <input
                          type="date"
                          v-model="purchase_date"
                          class="form-control"
                          placeholder="Receiving Date"
                        />
                      </div>
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @clicked="handleSubmit"
                        :disabler="submitDisabled"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Save</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>No Purchase Order available</h6>
                              <p>
                                No items found in purchase order list. Search
                                for product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import TypeHead from "@/components/TypeHead.vue";
export default {
  name: "PurchaseOrders",
  components: { TypeHead, Multiselect },
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    suppliers: [],
    supplier: null,
    record: {},
    purchase_date: new Date(TODAY)
      .toLocaleString("fr-CA", { timeZone: TIMEZONE })
      .slice(0, 10),
    editingMode: false,
  }),
  computed: {
    submitDisabled() {
      return (
        !this.items.length ||
        this.$helper.empty(this.supplier) ||
        this.items.some((item) => !item.store_purchase)
      );
    },
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
  },
  beforeCreate() {
    const reference = this.$route.params.reference;
    if (reference) {
      this.$http.get(`orders/items/list/${reference}`).then((response) => {
        this.items = response.data.items;
        this.record = response.data.record;
        this.editingMode = true;
        this.supplier = response.data.record.vendor;
        this.items.forEach((item) => {
          this.selectedItemIds.push(item.product.id);
        });
      });
    } else {
      this.$http.get("orders/items").then((response) => {
        this.items = response.data.rows;
        this.items.forEach((item) => {
          this.selectedItemIds.push(item.id);
          // item.total_amount = Number(item.cost) * Number(item.store_purchase);
        });
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$http.get("settings/suppliers?nopagination=1").then((response) => {
        this.suppliers = response.data.rows.data;
      });
    });
  },
  methods: {
    /**
     * Remove a product (item) from purchase order
     */
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    /**
     * Delete Purchase order Item not a product
     * This is for already added item
     */
    handleOrderItemDeletion(item, key) {
      this.$http
        .get(`orders/items/details/${item.id}/delete`)
        .then((response) => {
          if (response.data.status) {
            this.items.splice(key, 1);
            this.selectedItemIds.splice(key, 1);
          }
        });
    },
    /**
     * After selecting from the dropdown list
     */
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        item.total_amount = Number(item.cost) * Number(item.store_purchase);
        this.items.unshift(item);
        this.selectedItemIds.unshift(item.id);
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    findTotalAmount(item) {
      if (!this.$helper.empty(item.cost)) {
        item.total_amount = Number(item.cost) * Number(item.store_purchase);
      }
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var supplier = null;
          if (!this.$helper.empty(this.supplier)) {
            supplier = this.supplier.id;
          }
          const url = this.editingMode ? "orders/update" : "orders/store";
          this.$http
            .post(
              url,
              this.$helper.generateFormData({
                supplier: supplier,
                items: this.items,
                purchase_date: this.purchase_date,
                total_amount: this.grandTotal,
                record_id: this.record.id,
              })
            )
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                this.$router.push({ name: "OrdersReports" });
              }
            });
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
