/** global TIMEZONE */
import Paginations from "@/components/Paginations.vue";
export const dashboardServices = {
  components: {
    pagination: Paginations,
  },
  data: () => ({
    rows: [],
    currentPage: 0,
    rowCountPage: 45,
    totalRows: 0,
    pageRange: 10,
    recordsFrom: 0,
    recordsTo: 0,
    fetchUrl: null,
    excelFilename: null,
    fetchedData: {},
    editIndex: null,
    newFilter: {
      from: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
      to: new Date(TODAY)
        .toLocaleString("fr-CA", { timeZone: TIMEZONE })
        .slice(0, 10),
    },
    checkedRows: [],
    checkAll: false,
  }),
  computed: {
    hasKeyword() {
      return this.filterKey != null && this.filterKey.trim().length >= 2;
    },
    reportLabel() {
      let from = !this.newFilter.from
        ? new Date(TODAY)
            .toLocaleString("fr-CA", { timeZone: TIMEZONE })
            .slice(0, 10)
        : this.newFilter.from;
      if (!this.newFilter.to) return this.$helper.formatDate(from);
      else
        return `${this.$helper.formatDate(from)} - ${this.$helper.formatDate(
          this.newFilter.to
        )}`;
    },
    pageTitle() {
      return this.$route.meta.title;
    },
  },
  created() {
    if (this.fetchUrl) {
      const startDate = this.$route?.query?.start_date;
      const endDate = this.$route?.query?.end_date;
      if (startDate && endDate) {
        this.newFilter.from = startDate;
        this.newFilter.to = endDate;
        this.handlePaginationSearch(
          this.encodeQuery(this.fetchUrl, this.newFilter)
        );
      } else {
        this.handlePaginationSearch();
      }
    }
  },
  watch: {
    checkAll() {
      var checkbox = document.querySelectorAll(".row-checker");
      if (this.checkAll)
        checkbox.forEach((elt) => {
          if (!elt.checked) elt.click();
        });
      else
        checkbox.forEach((elt) => {
          if (elt.checked) elt.click();
        });
    },
  },
  methods: {
    handlePaginationSearch(fetchUrl = null) {
      let url = this.fetchUrl;
      if (typeof fetchUrl == "string") {
        url = fetchUrl;
      }
      if (this.hasKeyword) {
        this.$store.commit("SET_REQUEST_FLAG", "PAGINATION_SEARCH");
        if (url.indexOf("?") > -1)
          url += `&q=${this.filterKey}&per_page=${this.rowCountPage}`;
        else url += `?q=${this.filterKey}&per_page=${this.rowCountPage}`;
      }

      this.$http.get(this.encodeQuery(url, this.newFilter)).then((response) => {
        this.rows = response.data.rows.data;
        this.currentPage = response.data.rows.current_page;
        this.totalRows = response.data.rows.total;
        this.recordsFrom = response.data.rows.from;
        this.recordsTo = response.data.rows.to;
        this.fetchedData = response.data;
      });
    },
    handlePagination(obj) {
      this.rowCountPage = Number(obj.per_page);
     // var url = `${this.fetchUrl}?page=${obj.page_number}&per_page=${obj.per_page}&`;
      this.newFilter.page = obj.page_number;
      this.newFilter.per_page = obj.per_page;

      if (this.filterKey) {
        //url = `${this.fetchUrl}?page=${obj.page_number}&per_page=${obj.per_page}&q=${this.filterKey}&`;
        this.newFilter.q = this.filterKey;
      }
      const url = this.encodeQuery(this.fetchUrl, this.newFilter);
      /*let data = this.newFilter;
      for (let d in data) {
        if (!this.$helper.empty(data[d]))
          url +=
            encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
      } */
      this.$http.get(url).then((response) => {
        this.rows = response.data.rows.data;
        this.currentPage = response.data.rows.current_page;
        this.recordsFrom = response.data.rows.from;
        this.recordsTo = response.data.rows.to;
        this.totalRows = response.data.rows.total;
      });
    },
    computeUserAvatar(user) {
      var firstname = user.first_name[0];
      var lastname = user.last_name ? user.last_name[0] : "";
      return `${firstname} ${lastname}`;
    },
    /*getDateDifference(date, otherDate) {
      const diffDays = (date, otherDate) =>
        Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
      return diffDays;
    }, */
    handleRecordEdit(rows, record, index, url) {
      this.$http
        .post(url, this.$helper.generateFormData(record))
        .then((response) => {
          if (response.data.status) {
            rows[index] = response.data.record;
            this.toggleModal();
          }
        });
    },
    encodeQuery(url, data) {
      let query = "";
      for (let d in data) {
        if (
          !this.$helper.empty(data[d]) &&
          url.indexOf(`?${d}`) < 0 &&
          url.indexOf(`&${d}`) < 0
        )
          query +=
            encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
      }
      return url.indexOf("?") > -1
        ? `${url}&${query.slice(0, -1)}`
        : `${url}?${query.slice(0, -1)}`;
    },
    handlePaginationFilter() {
      let fetchUrl = this.encodeQuery(this.fetchUrl, this.newFilter);
      this.$store.commit("SET_REQUEST_FLAG", "PAGINATION_SEARCH");
      this.handlePaginationSearch(fetchUrl);
    },
    handleExcelExport() {
      let container = document.querySelector(".nk-tb-list");
      let head = container.querySelector(".nk-tb-item.nk-tb-head");
      let headers = [
        ...head.querySelectorAll(
          ".nk-tb-col:not(.nk-tb-col-check):not(.nk-tb-col-tools)"
        ),
      ].map((item) => item.textContent);
      let dataset = [];
      [...container.querySelectorAll(".nk-tb-item:not(.nk-tb-head)")].forEach(
        (row) => {
          let arr = [
            ...row.querySelectorAll(
              ".nk-tb-col:not(.nk-tb-col-check):not(.nk-tb-col-tools)"
            ),
          ].map((item) => item.textContent);
          dataset.push(arr);
        }
      );
      /**
       * Creating a temporary form
       */
      let dataForm = document.createElement("form");
      dataForm.target = "_blank";
      dataForm.method = "POST";
      dataForm.action = "https://tameaps.com/export.php";
      let records = document.createElement("input");
      let file_name = document.createElement("input");
      let headings = document.createElement("input");
      records.type = "hidden";
      file_name.type = "hidden";
      headings.type = "hidden";
      records.name = "dataset";
      records.value = JSON.stringify(dataset);
      file_name.name = "filename";
      file_name.value =
        this.excelFilename || this.pageTitle.replaceAll(" ", "-");
      headings.name = "columns";
      headings.value = JSON.stringify(headers);
      dataForm.appendChild(records);
      dataForm.appendChild(file_name);
      dataForm.appendChild(headings);
      dataForm.style.display = "none";
      document.body.appendChild(dataForm);
      dataForm.submit();
    },
    padNumber(number, targetedLength = 5) {
      let strNumber = number.toString();
      if (strNumber.length < targetedLength) {
        let padding = new Array(targetedLength - strNumber.length + 1).join(
          "0"
        );
        return padding + strNumber;
      }
    },
  },
};

/**
 * 'en-CA'
  'fr-CA'
  'lt-LT'
  'sv-FI'
  'sv-SE'
 */
